import * as React from 'react';
import { useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import TextField from '@mui/material/TextField';
import axios from 'axios';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function AddServiceDialog(props) {
    const { open, setOpen, item, loadFunc } = props;
    const content_ref = useRef();

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        setOpen(false);
    }

    const handleSubmit = () => {
        handleClose()
        axios.post(`/api/service/${item.id}/content`, { value: content_ref.current.value })
            .then(res => {
                if (res.status == 200) alert("Successfully saved.");
            })
            .then(() => {
                loadFunc();
            })
            .catch(err => { alert(err) })
    }

    const handleTest = () => {
        axios.post('/api/service/test_script', { script: content_ref.current.value })
            .then((res) => {
                alert(res.data)
                console.log(res.data)
            })
    }

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Edit Script
            </BootstrapDialogTitle>
            <DialogContent sx={{ width: '600px' }}>
                <TextField
                    sx={{ marginTop: '8px' }}
                    id="content"
                    name="content"
                    label="Content"
                    defaultValue={item.content}
                    inputRef={content_ref}
                    multiline
                    fullWidth
                    maxRows={100}
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleTest}>
                    Test
                </Button>
                <Button autoFocus onClick={handleSubmit}>
                    Save
                </Button>
                <Button autoFocus onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}
