import axios from "axios"

export const allowNotificationPermission = () => {
    let isNotificationSupported = 'Notification' in window
    if (isNotificationSupported) {
        Notification.requestPermission().then(result => {
            if (result !== 'granted') alert('권한 허용을 해 주셔야 PUSH 알림을 받을 수 있습니다.')
        })
    }
}

/* export const displayNotification = () => {
    if (Notification.permission == 'granted') {
        navigator.serviceWorker.getRegistration().then(function (reg) {
            var options = {
                body: 'Here is a notification body!',
                icon: 'images/example.png',
                vibrate: [100, 50, 100],
                data: {
                    dateOfArrival: Date.now(),
                    primaryKey: 1
                }
            };
            if (reg) reg.showNotification('Hello world!', options);
        });
    }
} */

export function registerSW(config) {
    if ("serviceWorker" in navigator) {
        window.addEventListener("load", () => {
            navigator.serviceWorker.register("/sw.js");
        });
    }
}

function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export function createSubscription() {
    if (!('serviceWorker' in navigator)) return

    let reg
    navigator.serviceWorker.ready.then(swreg => {
        reg = swreg
        return swreg.pushManager.getSubscription()
    }).then(async sub => {
        // subscription 없음
        if (sub === null) {
            let pubkey = await axios.get('/api/push/key')
            return reg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(pubkey.data.key)
            })
        }
    }).then(sub => {
        if (sub !== undefined) {
            return axios.post('/api/push/subscribe', sub)
        }
    }).then(() => {
        alert("Succesfully subscribed.")
    })
        .catch(err => console.log(err))
}

export function deleteSubscription() {
    navigator.serviceWorker.ready.then(reg => {
        reg.pushManager.getSubscription().then(sub => {
            sub.unsubscribe().then(() => {
                alert("Successfully unsubscribed.")
            }).catch(e => {
                console.error(e)
            })
        })
    })
}

export function createTelegramSubscription() {
    axios.post('/api/push/telegram')
        .then((res) => {
            if (res.status == 201) alert("Successfully subscribed telegram")
            else if (res.status == 200) alert("Succesfully unsubscribed telegram")
            else alert("Unknown error " + res.status)
        })
}