import * as React from 'react';
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, InputLabel } from '@mui/material';
import axios from 'axios';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function AddServiceDialog(props) {
    const { open, setOpen, handleSubmit } = props;
    const [type, setType] = useState(0);
    const content_ref = useRef();

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        setOpen(false);
    }

    const handleTest = () => {
        axios.post('/api/service/test_script', { script: content_ref.current.value })
            .then((res) => {
                console.log(res.data)
            })
    }

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Add service
            </BootstrapDialogTitle>
            <Box component="form" noValidate onSubmit={handleSubmit}>
                <DialogContent dividers >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                name="name"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="type-label">Type</InputLabel>
                                <Select
                                    labelId="type-label"
                                    id="type"
                                    name="type"
                                    value={type}
                                    label="Type"
                                    onChange={(event) => { setType(event.target.value) }}
                                >
                                    <MenuItem value={0}>Static</MenuItem>
                                    <MenuItem value={1}>Dynamic</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="cron"
                                disabled={type === 1}
                                fullWidth
                                id="cron"
                                label="Cron"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="content_name"
                                label="Content name"
                                id="content_name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="content"
                                name="content"
                                label="Content"
                                disabled={type === 1}
                                defaultValue="(async ()=>{
                                    const raw = await axios.get('https://dog.ceo/api/breeds/list/all');
                                    return raw.data.status
                                    })()"
                                inputRef={content_ref}
                                multiline
                                fullWidth
                                maxRows={100}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleTest}>
                        Test
                    </Button>
                    <Button autoFocus type="submit">
                        Save
                    </Button>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Box>
        </BootstrapDialog>
    );
}
