import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { IconButton, Switch, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { useState, useEffect } from 'react';
import AddServiceDialog from './AddServiceDialog';
import EditScriptDialog from './EditScriptDialog';
// TODO: change to alert dialog


export default function Orders() {
    const [rows, setRows] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [formDialogOpen, setFormDialogOpen] = React.useState(false);
    const [editScriptDialogOpen, setEditScriptDialogOpen] = React.useState(false);
    const [tmpItem, setTmpItem] = React.useState({ id: 0, content: "" });

    const menuClose = () => {
        setAnchorEl(null);
    }

    useEffect(() => {
        loadRows();
    }, []);

    const loadRows = () => {
        axios.get('/api/service')
            .then(({ data }) => setRows(data))
            .catch(err => alert(err));
    }

    const deleteRow = (id) => {
        axios.delete('/api/service/' + id)
            .then(res => {
                if (res.status == 200) alert("Successfully deleted.");
            })
            .then(() => {
                loadRows();
            })
            .catch(err => alert(err));
    }

    const updateMode = (id, value) => {
        axios.post(`/api/service/${id}/mode`, { value })
            .catch(err => alert(err));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setFormDialogOpen(false);
        const data = new FormData(event.currentTarget);
        const object = {};
        data.forEach((value, key) => object[key] = value);
        axios.post('/api/service', object)
            .then(res => {
                if (res.status == 200) alert("Successfully saved.");
            })
            .then(() => {
                loadRows();
            })
            .catch(err => alert(err));
    }

    return (
        <React.Fragment>
            <Title>Registered Service</Title>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl ? anchorEl.target : null}
                open={Boolean(anchorEl)}
                onClose={menuClose}
            >
                <MenuItem onClick={() => {
                    console.log(anchorEl.id, "execute");
                    menuClose();
                }}>Execute</MenuItem>
                <MenuItem onClick={() => {
                    deleteRow(anchorEl.id);
                    menuClose();
                }}>Delete</MenuItem>
            </Menu>
            <AddServiceDialog open={formDialogOpen} setOpen={setFormDialogOpen} handleSubmit={handleSubmit}></AddServiceDialog>
            <EditScriptDialog open={editScriptDialogOpen} setOpen={setEditScriptDialogOpen} item={tmpItem} loadFunc={loadRows}></EditScriptDialog>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Cron</TableCell>
                        <TableCell>Content name</TableCell>
                        <TableCell>Success / Failed</TableCell>
                        <TableCell>Last Executed Date</TableCell>
                        <TableCell>Mode</TableCell>
                        <TableCell>Options</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.type == 0 ? 'Static' : (row.type == 1 ? 'Dynamic' : 'Unknown')}</TableCell>
                            <TableCell>{row.cron}</TableCell>
                            <TableCell>
                                <Link color="primary" href="#" onClick={() => {
                                    setTmpItem({ id: row.id, content: row.content })
                                    setEditScriptDialogOpen(true)
                                }}>
                                    {row.content_name}
                                </Link>
                            </TableCell>
                            <TableCell>{`${row.success} / ${row.failed}`}</TableCell>
                            <TableCell>{row.last_exec ?? '-'}</TableCell>
                            <TableCell>
                                <Switch defaultChecked size="small" defaultChecked={row.mode} onChange={(event) => {
                                    updateMode(row.id, event.target.checked)
                                }} />
                            </TableCell>
                            <TableCell>
                                <IconButton aria-label="option" onClick={(event) => {
                                    setAnchorEl({ target: event.currentTarget, id: row.id });
                                }}>
                                    <MoreVertIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Link color="primary" href="#" onClick={() => { setFormDialogOpen(true) }} sx={{ mt: 3 }}>
                Add more service
            </Link>
        </React.Fragment>
    );
}